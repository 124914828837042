<template>
  <div class="animated fadeIn">
    <b-card header="Report Problem" class="card-columns cols-2">
      <b-form @submit.prevent="create" @reset="reset">

        <div class="table-responsive">
          <table class="table-m">     
            <tr>
              <td><label style="width: 200px;">{{ $t('message.selectReport') }}</label></td>
              <td>
                <label style="width: 300px;">
                  <b-form-select :options="$t('message.arrays.reportType')" v-model="selectReportId" text-field="name" value-field="id"></b-form-select>  
                </label>
              </td>
            </tr>

            <template v-if="selectReportId === 1">
              <tr>
                <td>{{ $t('message.machineIdFarm') }}</td>
                <td>
                  <model-list-select :list="machineData" v-model="newReportData.machineId" option-value="machineId" option-text="name" :placeholder="$t('message.machineIdFarm')"></model-list-select>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.deviceId') }}</td>
                <td>
                  <b-form-input type="number" :placeholder="$t('message.deviceId')" v-model="newReportData.deviceId"></b-form-input>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.receiveChannel') }}</td>
                <td>
                  <b-form-input type="text" placeholder="Ex. Line@ Call FB" v-model="newReportData.channel"></b-form-input>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.time') }}</td>
                <td>
                  <date-picker type="datetime" v-model="newReportData.timestamp" lang="en" format="yyyy-MM-dd HH:mm:ss"></date-picker>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.problemDetail') }}</td>
                <td>
                  <b-form-textarea type="text" v-model="newReportData.details"></b-form-textarea>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.problemSolution') }}</td>
                <td>
                  <b-form-textarea type="text" v-model="newReportData.solution"></b-form-textarea>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.informant') }}</td>
                <td>
                  <b-form-textarea type="text" placeholder="PHONE NO./FB/LINE" v-model="newReportData.informant"></b-form-textarea>
                </td>
              </tr>
            </template>

            <template v-else-if="selectReportId === 2">
              <tr>
                <td>{{ $t('message.machineIdFarm') }}</td>
                <td>
                  <model-list-select :list="machineData" v-model="newReportData.machineId" option-value="machineId" option-text="name" :placeholder="$t('message.machineIdFarm')"></model-list-select>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.deviceId') }}</td>
                <td>
                  <b-form-input type="number" :placeholder="$t('message.deviceId')" v-model="newReportData.deviceId"></b-form-input>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.problemDetail') }}</td>
                <td>
                  <b-form-textarea type="text" v-model="newReportData.details"></b-form-textarea>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.type') }}</td>
                <td>
                  <b-form-textarea type="text" v-model="newReportData.type"></b-form-textarea>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.warranty') }}</td>
                <td>
                  <b-form-select v-model="newReportData.warranty" :options="options"></b-form-select>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.authority') }}</td>
                <td>
                  <b-form-textarea type="text" v-model="newReportData.authority"></b-form-textarea>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.department') }}</td>
                <td>
                  <b-form-textarea type="text" v-model="newReportData.department"></b-form-textarea>
                </td>
              </tr>
            </template>

            <template v-else-if="selectReportId === 3">
              <tr>
                <td>{{ $t('message.machineIdFarm') }}</td>
                <td>
                  <model-list-select :list="machineData" v-model="newReportData.machineId" option-value="machineId" option-text="name" :placeholder="$t('message.machineIdFarm')"></model-list-select>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.deviceId') }}</td>
                <td>
                  <b-form-input type="number" :placeholder="$t('message.deviceId')" v-model="newReportData.deviceId"></b-form-input>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.detail') }}</td>
                <td>
                  <b-form-textarea type="text" v-model="newReportData.details"></b-form-textarea>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.maintenanceDate') }}</td>
                <td>
                  <date-picker type="datetime" v-model="newReportData.date" lang="en" format="yyyy-MM-dd HH:mm:ss"></date-picker>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.authority') }}</td>
                <td>
                  <b-form-textarea type="text" v-model="newReportData.authority"></b-form-textarea>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.department') }}</td>
                <td>
                  <b-form-textarea type="text" v-model="newReportData.department"></b-form-textarea>
                </td>
              </tr>
            </template>

            <template v-else-if="selectReportId === 4">
              <tr>
                <td>{{ $t('message.machineIdFarm') }}</td>
                <td>
                  <model-list-select :list="machineData" v-model="newReportData.machineId" option-value="machineId" option-text="name" :placeholder="$t('message.machineIdFarm')"></model-list-select>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.errorDevice') }} (option)</td>
                <td>
                  <b-form-input type="number" v-model.number="newReportData.errorDevice"></b-form-input>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.timeStart') }}</td>
                <td>
                  <date-picker type="datetime" v-model="newReportData.startTime" lang="en" format="yyyy-MM-dd HH:mm:ss"></date-picker>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.timeSolve') }}</td>
                <td>
                  <date-picker type="datetime" v-model="newReportData.endTime" lang="en" format="yyyy-MM-dd HH:mm:ss"></date-picker>
                </td>
              </tr>
            </template>
          </table>
          <br />
          <div align="right">
            <b-button type="reset" variant="danger" size="lg">{{ $t('message.clearData') }}</b-button>&nbsp;
            <b-button type="submit" variant="primary" size="lg">{{ $t('message.create') }}</b-button>
            <!-- <br></br><h4 class='invalidlogin' style="color:red; display:none;">{{ $t('message.cannotCreateMachine') }}</h4> -->
          </div>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'
import webServices from '../../script'
import DatePicker from '../../custom_modules/vue2-datepicker'
import { ModelListSelect, MultiListSelect } from '../../custom_modules/search-select'
export default {
  name: 'ProblemReport',
  components: {
    DatePicker,
    ModelListSelect,
    MultiListSelect
  },
  data() {
    return {
      newReportData: {},
      machineData: [],
      selectReportId: 0,
      status: [
        { value: 'SUCCESS', text: 'แก้ไขแล้ว' },
        { value: 'PROCESSING', text: 'กำลังดำเนินการ' },
        { value: 'RECEIVED', text: 'ได้รับแจ้งปัญหาแล้ว' }
      ],
      options: [
        { value: true, text: 'ในประกัน' },
        { value: false, text: 'นอกประกัน' }
      ]
    }
  },
  methods: {
    getMachine() {
      this.$Progress.start()
      webServices
        .getMachineList(window.localStorage.getItem('userId'))
        .then(res => {
          this.$Progress.finish()
          this.machineData = res.data
          // console.log(res.data)
        })
        .catch(err => {
          this.$Progress.fail()
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          this.$toast.error({
            title: 'ERROR',
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          })
          console.log('error @machine')
          console.log(err)
        })
    },
    create() {
      if (this.selectReportId === 1) {
        this.newReportData.timestamp = moment(this.newReportData.timestamp).format('YYYY-MM-DD HH:mm:ss')
        webServices
          .createIncompleteReport(this.newReportData)
          .then(res => {
            if (res.data.success) {
              this.$Progress.finish()
              this.$toast.success({
                title: 'SUCCESS',
                message: 'สำเร็จ'
              })
              this.modalData = {}
            } else {
              this.$Progress.fail()
              const error = webServices.showError(res.data)
              this.$toast.error({
                title: 'ERROR',
                message: JSON.stringify(error)
              })
            }
            this.newReportData = {}
          })
          .catch(err => {
            this.$Progress.fail()
            if (err.response.status === 401) {
              webServices.tokenExpire()
            }
            console.log(err)
            const errorText = {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(errorText)
            })
          })
      } else if (this.selectReportId === 2) {
        webServices
          .createMaintenanceHistoryReport(this.newReportData)
          .then(res => {
            if (res.data.success) {
              this.$Progress.finish()
              this.$toast.success({
                title: 'SUCCESS',
                message: 'สำเร็จ'
              })
              this.modalData = {}
            } else {
              this.$Progress.fail()
              const error = webServices.showError(res.data)
              this.$toast.error({
                title: 'ERROR',
                message: JSON.stringify(error)
              })
            }
            this.newReportData = {}
          })
          .catch(err => {
            this.$Progress.fail()
            if (err.response.status === 401) {
              webServices.tokenExpire()
            }
            console.log(err)
            const errorText = {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(errorText)
            })
          })
      } else if (this.selectReportId === 3) {
        this.newReportData.date = moment(this.newReportData.date).format('YYYY-MM-DD HH:mm:ss')
        webServices
          .createMaintenanceRemainderReport(this.newReportData)
          .then(res => {
            if (res.data.success) {
              this.$Progress.finish()
              this.$toast.success({
                title: 'SUCCESS',
                message: 'สำเร็จ'
              })
              this.modalData = {}
            } else {
              this.$Progress.fail()
              const error = webServices.showError(res.data)
              this.$toast.error({
                title: 'ERROR',
                message: JSON.stringify(error)
              })
            }
            this.newReportData = {}
          })
          .catch(err => {
            this.$Progress.fail()
            if (err.response.status === 401) {
              webServices.tokenExpire()
            }
            console.log(err)
            const errorText = {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(errorText)
            })
          })
      } else if (this.selectReportId === 4) {
        webServices
          .createElectricityProblemReport(this.newReportData)
          .then(res => {
            if (res.data.success) {
              this.$Progress.finish()
              this.$toast.success({
                title: 'SUCCESS',
                message: 'สำเร็จ'
              })
              this.modalData = {}
            } else {
              this.$Progress.fail()
              const error = webServices.showError(res.data)
              this.$toast.error({
                title: 'ERROR',
                message: JSON.stringify(error)
              })
            }
            this.newReportData = {}
          })
          .catch(err => {
            this.$Progress.fail()
            if (err.response.status === 401) {
              webServices.tokenExpire()
            }
            console.log(err)
            const errorText = {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(errorText)
            })
          })
      }
    },
    reset() {
      this.newReportData = {}
    }
  },
  mounted() {
    this.getMachine()
  }
}
</script>
<style>
</style>
